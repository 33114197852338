import { DatePipe, NgClass } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import {
  Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import * as moment from 'moment';
import { APPOINTMENT_CONSTANT } from '../../../../constants/appointment-constants';
import { DATE_TIME_ISO_T_FORMAT, TIME_FORMAT } from '../../../../constants/date.constants';
import { TRANSLATION_TEMPLATES } from '../../../../constants/translation-templates-constants';
import { AppointmentServiceDao } from '../../../../db-models/appointment-service-dao';
import { CouponDbModel } from '../../../../db-models/coupon-db.model';
import { AppointmentsSlotsDao } from '../../../../db-models/free-appointment-dao';
import { StoresDao } from '../../../../db-models/stores-dao';
import { CustomBookingMessageTemplate } from '../../../../db-models/widget-conf-dao';
import { CartItem } from '../../../../models/cart.model';
import { GlobalObjects, Partner } from '../../../../models/global';
import { AppointmentState, ConferenceState, UserState } from '../../../../models/state.model';
import { AppointmentService } from '../../../../services/appointment.service';
import { FormsService } from '../../../../services/forms.service';
import { HelperService } from '../../../../services/helper.service';
import { LoggerService } from '../../../../services/logger.service';
import { UtilService } from '../../../../services/util.service';
import { NgxClampComponent } from '../../../../shared/components/ngx-clamp/ngx-clamp.component';
import { CalioCurrencyPipe } from '../../../../shared/pipes/calio-currency.pipe';
import { CalioMeetingTemplatePipe } from '../../../../shared/pipes/calio-meeting-template.pipe';
import { DateUtcPipe } from '../../../../shared/pipes/date_utc.pipe';
import { TranslationPipe } from '../../../../shared/pipes/translation.pipe';
import { TrustHtmlPipe } from '../../../../shared/pipes/trust-html.pipe';
import { CwCardHeaderComponent } from '../../../common/theme/cw-card-header/cw-card-header.component';
import { CwNewCardComponent } from '../../../common/theme/cw-new-card/cw-new-card.component';

@Component({
  selector: 'app-appointment-cart-overview',
  templateUrl: './appointment-cart-overview.component.html',
  styleUrls: ['./appointment-cart-overview.component.scss'],
  standalone: true,
  imports: [NgClass, CwNewCardComponent, CwCardHeaderComponent, NgxClampComponent, DatePipe, DateUtcPipe, TranslateModule, TranslationPipe, TrustHtmlPipe, CalioCurrencyPipe, CalioMeetingTemplatePipe]
})
export class AppointmentCartOverviewComponent implements OnInit, OnChanges {

  @Input() cart: CartItem[];
  @Input() partner: Partner;
  @Input() totalCartDuration: number;
  @Input() totalCartPrice: number;
  @Input() appoinrmentServicesAvailable: AppointmentServiceDao[];
  @Input() lang: string;
  @Input() coupon: CouponDbModel;
  @Input() finalCouponDiscount = 0;
  @Input() showTotalBlock = false;
  @Input() courtesyForm: string;
  @Input() widgetTemplates: CustomBookingMessageTemplate[] = [];
  @Input() appointmentState: AppointmentState;
  @Input() stores: StoresDao[] = [];
  @Input() globals: GlobalObjects;
  @Input() userState: UserState;
  @Input() conferenceState: ConferenceState;
  @Input() debug: string;
  @Input() token: string;
  @Input() mode = APPOINTMENT_CONSTANT.PERSONAL_INFO;

  @Output() removeFromCartEvent = new EventEmitter<string>();
  @Output() updateCartEvent = new EventEmitter<any>();

  readonly timeFormat = TIME_FORMAT;
  readonly dateTimeIsoTFormat = DATE_TIME_ISO_T_FORMAT;
  readonly templateContent = TRANSLATION_TEMPLATES;
  readonly appointmentConstant = APPOINTMENT_CONSTANT;

  finalTotal: number;
  templateTitle: CustomBookingMessageTemplate;
  templateSubTitle: CustomBookingMessageTemplate;
  relatedAppointmentServices: {
    relatedAppointmentCardId: number,
    relatedServiceId: number,
    relatedServiceData: AppointmentServiceDao,
    originalAppointmentService: AppointmentServiceDao,
    beforeDates: AppointmentsSlotsDao[],
    afterDates: AppointmentsSlotsDao[],
    allDates: AppointmentsSlotsDao[],
    isAddedToCart: boolean,
  }[] = [];
  customCardClass = '';

  constructor(
    private appointmentService: AppointmentService,
    private translateService: TranslateService,
    private formService: FormsService,
    private utilService: UtilService,
    private helperService: HelperService,
  ) {
    this.translateService.onLangChange.subscribe(language => this.lang = language.lang);
  }

  ngOnInit() {
    if (
      this.partner.split_personal_form_and_summary_in_widget === 1 &&
      this.mode === this.appointmentConstant.PERSONAL_INFO
    ) {
      this.mode = this.globals.isInternal ? this.appointmentConstant.PERSONAL_INFO : this.appointmentConstant.SUMMARY;
    }

    this.getRelatedServiceFreeAppointments();
    this.customCardClass += this.mode === this.appointmentConstant.SUMMARY ? ' p-0' : '';
  }

  getServicesByIds({ serviceIds, returnKey = 'services' }: { serviceIds: number[]; returnKey?: string }) {
    return this.appointmentService.getServicesByIds({ serviceIds, returnKey }, this.appoinrmentServicesAvailable);
  }

  removeFromCart(cartItemId: string, relatedAppointmentCardId: number) {
    if (this.relatedAppointmentServices?.length && relatedAppointmentCardId) {
      for (const relatedAppointmentService of this.relatedAppointmentServices) {
        if (Number(relatedAppointmentService?.relatedAppointmentCardId) === relatedAppointmentCardId) {
          relatedAppointmentService.isAddedToCart = false;
        }
      }
    } else {
      // Check for related service after 1 second delay as we need to wait this.cart variable to be updated
      setTimeout(() => this.getRelatedServiceFreeAppointments(), 1000);
    }

    this.removeFromCartEvent.next(cartItemId);
  }

  getFinalTotal(): number {
    if (this.totalCartPrice > 0) {
      this.finalTotal = Number(this.totalCartPrice) - Number(this.finalCouponDiscount);
      return this.finalTotal;
    } else {
      this.finalTotal = 0;
      return this.finalTotal;
    }
  }

  getTotalTaxPrice(): number {
    let taxPrice = 0;
    for (const c of this.cart) {
      taxPrice = taxPrice + c.total.taxPrice;
    }

    taxPrice = taxPrice - this.finalCouponDiscount;
    return Number(taxPrice.toFixed(2));
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.getFinalTotal();

    if (changes?.widgetTemplates?.currentValue) {
      this.widgetTemplates?.length && this.setupTemplates();
    }
  }

  setupTemplates(): void {
    this.templateTitle = this.widgetTemplates.find(template => template.id === 124);
    this.templateTitle && (this.templateTitle.is_multi_language = 1);
    this.templateSubTitle = this.widgetTemplates.find(template => template.id === 125);
    this.templateSubTitle && (this.templateSubTitle.is_multi_language = 1);
  }

  getAppointmentServiceById(serviceId: number): AppointmentServiceDao {
    return this.appoinrmentServicesAvailable?.find(service => service?.id === serviceId);
  }

  getRelatedServiceFreeAppointments(): void {
    const relatedServices = this.cart[0]?.relatedServiceIds?.length ? this.cart[0]?.relatedServiceIds : [];
    if (relatedServices?.length) {
      const relatedServiceAlreadyPresentInCart = this.cart.find(service => service.servicesIds.includes(relatedServices[0]));
      if (!relatedServiceAlreadyPresentInCart) {
        // As of now sending only 1 related service
        this.getFreeAppointments(
          this.appointmentState.day.date,
          0,
          relatedServices[0]?.toString(),
          this.appointmentState.store,
          this.cart[0].servicesIds[0],
          this.conferenceState?.uuid || undefined
        );
      }
    }
  }

  getFreeAppointments(
    date: Date,
    workerId: number,
    appointmentIds: string,
    storeId: number,
    parentServiceId = 0,
    conferenceUuid = undefined
  ) {
    this.formService.getDaysWiseFreeSlots(
      workerId,
      storeId,
      appointmentIds,
      this.utilService.dateToStr(date),
      this.globals.isInternal,
      conferenceUuid,
      this.debug,
      this.token
    ).subscribe({
      next: freeAppointments => {
        let availableSlotsArray: {
          [key: string]: AppointmentsSlotsDao[]
        }[] = [];

        if (freeAppointments && !('errors' in freeAppointments)) {
          // preparing array that has day wise availabilites
          freeAppointments.available_minutes_blocks?.forEach(daysWiseSlots => {
            daysWiseSlots.workers_minutes_blocks?.forEach(workerMinutesBlock => {
              workerMinutesBlock.minutes_blocks.forEach(availabilities => {
                availabilities.appointments_slots?.forEach(slot => {
                  if (!slot.is_reserved) {
                    const workerUuids = workerMinutesBlock.workers.map(worker => worker.uuid);
                    !availableSlotsArray[daysWiseSlots.date] && (availableSlotsArray[daysWiseSlots.date] = []);
                    availableSlotsArray[daysWiseSlots.date].push({
                      start: slot.appointment_start,
                      end: slot.appointment_end,
                      worker: workerMinutesBlock.worker,
                      workerUuids,
                      store: workerMinutesBlock.store,
                      startHour: parseInt(slot.appointment_start.split(' ')[1].split(':')[0], 10),
                      short_start: slot.appointment_short_start,
                      location: slot.location,
                      isReserved: slot.is_reserved,
                      ...(parentServiceId ? { parentServiceId } : {})
                    });
                  }
                });
              });
            });
          });

          Object.keys(availableSlotsArray)?.length && this.setupSlots(availableSlotsArray);
        }
      },
      error: (error: HttpErrorResponse) => LoggerService.error(error)
    });
  }

  setupSlots(freeAppointments: { [key: string]: AppointmentsSlotsDao[]; }[]) {
    this.cart?.forEach(cart => {
      cart?.relatedServices?.forEach(relatedService => {
        const beforeDates: AppointmentsSlotsDao[] = [];
        const afterDates: AppointmentsSlotsDao[] = [];
        let allDates: AppointmentsSlotsDao[] = [];
        let finalDates: AppointmentsSlotsDao[] = [];

        Object.keys(freeAppointments).forEach(date => {
          freeAppointments[date]?.forEach((freeAppointment: AppointmentsSlotsDao) => {
            if (moment(freeAppointment.end, this.dateTimeIsoTFormat).isSame(moment(cart.startTime, this.dateTimeIsoTFormat))) {
              beforeDates.push(freeAppointment);
            }

            if (moment(freeAppointment.start, this.dateTimeIsoTFormat).isSame(moment(cart.endTime, this.dateTimeIsoTFormat))) {
              afterDates.push(freeAppointment);
            }
          });
        });


        beforeDates?.length && (allDates = allDates.concat(beforeDates));
        afterDates?.length && (allDates = allDates.concat(afterDates));

        if (allDates?.length) {

          // Check suggestions are getting conflicted with other items of cart
          allDates.forEach(date => {
            let isConflicts = false;
            for (const cartItem in this.cart) {
              if (
                date.start > this.cart[cartItem]['startTime'] && date.start < this.cart[cartItem]['endTime'] ||
                date.start <= this.cart[cartItem]['startTime'] && date.end >= this.cart[cartItem]['startTime']
              ) {
                isConflicts = true;
                break;
              }
            }

            if (!isConflicts) {
              finalDates.push(date);
            }
          });

          if (finalDates?.length) {
            this.relatedAppointmentServices.push({
              relatedAppointmentCardId: this.helperService.random(1000000, 1),
              relatedServiceId: relatedService?.id,
              relatedServiceData: relatedService,
              originalAppointmentService: this.getAppointmentServiceById(relatedService?.related_appointment_service_id),
              beforeDates,
              afterDates,
              allDates: finalDates,
              isAddedToCart: false,
            });
          }
        }
      });
    });
  }

  onSelectSlot(
    relatedAppointmentService: {
      relatedAppointmentCardId: number,
      relatedServiceId: number,
      relatedServiceData: AppointmentServiceDao,
      originalAppointmentService: AppointmentServiceDao,
      beforeDates: AppointmentsSlotsDao[],
      afterDates: AppointmentsSlotsDao[],
      allDates: AppointmentsSlotsDao[],
      isAddedToCart: boolean,
    },
    freeAppointment: AppointmentsSlotsDao,
  ) {
    relatedAppointmentService.isAddedToCart = true;
    this.updateCartEvent.emit({
      event: { target: { checked: true } },
      appointment: freeAppointment,
      ignoreRelatedServiceId: true,
      ignoreAppointmentStateServices: true,
      relatedServiceId: relatedAppointmentService?.relatedServiceId,
      relatedAppointmentCardId: relatedAppointmentService?.relatedAppointmentCardId,
      refreshFormAndCartData: true,
    });
  }
}
