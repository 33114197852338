export const fr_CH = {
  customer_type: 'Type de client',
  manage_appointment_now: 'Gérer le rendez-vous maintenant',
  maintenance_page_title: 'Calenso est en cours d\'amélioration pour vous en ce moment même.',
  maintenance_page_subtitle: 'Calenso est actuellement en cours de mise à jour vers la dernière version. Veuillez être patient et revenir dans quelques minutes.',
  maintenance_page_button: 'Vers la page d\'état',
  load_more: 'Charger plus',
  show_more: 'En savoir plus',
  internal_label: 'Interne',
  check_availability_for_anyone: 'Vérifier la disponibilité de toutes les ressources',
  store_not_found: 'Aucune filiale trouvée',
  appointment: 'Rendez-vous',
  event: 'Nominations de groupe',
  resource: 'Ressource',
  morgen: 'demain',
  vormittag: 'matin',
  mittag: 'midi',
  nachmittag: 'après-midi',
  abend: 'soir',
  nacht: 'nuit',
  free: 'gratuit',
  hoursShort: '',
  minutesShort: 'min',
  mondayLong: 'lundi',
  day1: 'LU',
  tuesdayLong: 'mardi',
  day2: 'MA',
  wednesdayLong: 'mércredi',
  day3: 'ME',
  thursdayLong: 'jeudi',
  day4: 'JE',
  fridayLong: 'vendredi',
  day5: 'VE',
  saturdayLong: 'samedi',
  day6: 'SA',
  sundayLong: 'dimanche',
  day0: 'DI',
  Januar: 'Janvier',
  Februar: 'Février',
  März: 'Mars',
  April: 'Avril',
  Mai: 'Mai',
  Juni: 'Juin',
  Juli: 'Juillet',
  August: 'Août',
  September: 'Septembre',
  Oktober: 'Octobre',
  November: 'Novembre',
  Dezember: 'Décembre',
  "shortMonths": {
    "Januar": "Janv",
    "Februar": "Févr",
    "März": "Mars",
    "April": "Avr",
    "Mai": "Mai",
    "Juni": "Juin",
    "Juli": "Juil",
    "August": "Août",
    "September": "Sept",
    "Oktober": "Oct",
    "November": "Nov",
    "Dezember": "Déc"
  },
  du: 'tu',
  sie: 'tu',
  havingFormDu: 'as',
  havingFormSie: 'as',
  yes: "Oui",
  no: "Non",
  confirm: {
    title: 'Confirmer la réservation',
    confirmationText: 'Tu es {{ cartLength }} Mettre ce rendez-vous dans votre panier. Souhaitez-vous réserver toutes ces rendez-vous obligatoires?',
    confirmationTextSie: 'Tu es {{ cartLength }} Mettre ce rendez-vous dans votre panier. Souhaitez-vous réserver toutes ces rendez-vous obligatoires?',
    ok: 'réservation',
    cancel: 'annuler'
  },
  internal: {
    search_customer: 'Recherche de clients existants et pré-remplissage du formulaire',
    selectPlaceholder: 'Chercher le client',
    customerDetails: 'Détails du client',
    noCustomer: 'Il n\'y a pas de client configuré',
    create_customer: 'Créer un client'
  },
  common: {
    report_bug: 'Signaler un bug',
    subscription_error_title: 'Pas d\'abonnement!',
    subscription_error_desc: 'Désolé, aucun abonnement n\'a pu être trouvé. Veuillez contacter Calenso Support.',
    next: 'Continuer',
    previous: 'Retour',
    reset: 'Réinitialiser l\'application',
    resetSuccess: 'Le stockage local a été supprimé.',
    loading: 'chargement ...',
    durationHour: ' heure(s)',
    durationMinute: ' min.',
    clock: 'heure',
    disclaimer: 'Ce service est fourni par <a href=\'https://www.calenso.com\' class=\'cal-link\' target=\'_blank\'>Calenso</a>.',
    book: 'Réservé',
    choseBookType: 'Que voulez-vous réserver?',
    company: 'Entreprise',
    prename: 'Prénom',
    lastname: 'Nom',
    email: 'E-Mail',
    phone: 'Téléphone',
    address: 'Adresse',
    createAccount: 'Créer un compte client (compte utilisateur avec toutes les inscriptions)',
    noDateError: 'Le rendez-vous souhaité a été réservé par quelqu\'un d\'autre, revenez en arrière et choisissez un nouveau rendez-vous.',
    devNotice: 'Le widget de réservation est connecté au backend DEV. Faites attention!',
    store_access_warning: 'L\'accès à la mémoire du navigateur est restreint en raison de vos paramètres de confidentialité.Toutes les fonctions du widget de réservation pourraient ne pas être disponibles.',
    save: "Enregistrer",
    cancel: "Annuler",
    additionalParticipantsUpTo: "Vous pouvez ajouter jusqu'à {{ number }} invités",
    additionalGuest: 'Participants supplémentaires',
    addAdditionalGuest: 'Ajouter des participants',
    additionalGuestCartLabel: '{{ number }} invité supplémentaire',
    additionalGuestsCartLabel: '{{ number }} d\'invités supplémentaires',
    widget_disabled: 'Le widget de réservation est actuellement désactivé',
    widget_disabled_description: 'Le widget de réservation est temporairement désactivé. Veuillez contacter le support Calenso (Error-Code : PMT-01).',
    no_workers_widget_disabled_description: 'Aucun rendez-vous ne peut être réservé pour le moment car aucune ressource n’est activée.',
    workers_not_available: 'Aucun service réservable trouvé',
    workers_not_available_description: 'Aucun service réservable n\'a été enregistré. Veuillez vous assurer qu\'au moins un service est disponible et qu\'il est affecté à une ressource réservable (capacité). Il ne suffit pas d\'entrer dans une catégorie de service pour pouvoir être réservé.',
    price_from_label: 'à partir de',
    close: 'Fermer',
    configuration_error: 'Erreur de configuration',
    resource_is_not_bookable: 'La ressource sélectionnée ne peut pas être réservée.',
    verification_token_missing: 'Le widget de réservation est utilisé en mode interne et nécessite donc un jeton de vérification. Veuillez contacter le support Calenso.',
    invalid_verification_code: 'L\'accès au widget en mode interne nécessite un jeton valide.Assurez - vous que le jeton est valide et réessayez.',
    internal_disabled: 'Le widget de réservation interne est désactivé.',
    evnet_disabled_error: "L'événement demandé est désactivé par le partenaire. Veuillez contacter votre administrateur.",
    conference_invalid_link: "Il semble que le lien de la conférence fourni ne soit pas valide. Veuillez vérifier le lien et réessayer.",
    conference_expired_link: "Nous sommes désolés, mais ce lien de conférence a expiré. Veuillez contacter l'organisateur pour obtenir de l'aide supplémentaire.",
    conference_not_belonging: "Oups, il semble que cette conférence soit liée à un autre partenaire.",
    conference_booked: "Oups, il semble que ce lien de conférence a déjà été utilisé et réservé. Si vous avez des questions ou avez besoin d'aide, n'hésitez pas à contacter les organisateurs.",
    conference_invalid_configurations: "La conférence n'est pas disponible en raison de configurations internes invalides. Pour plus d'aide, veuillez contacter l'organisateur.",
    location: "Emplacement"
  },
  appointments: {
    step1: {
      distance_from_store: '{{ distance }} km de distance',
      search_store_postcode_helper_text: 'Entrez le code postal pour rechercher l\'agence la plus proche',
      search_services_helper_text: 'Entrez un terme de recherche pour rechercher des services',
      title: 'Choix',
      chooseStore: 'Choisir la filiale',
      chooseService: 'Choisir les services',
      nextButton: 'Continuer',
      search_store_by_postcode_placeholder: 'Insérez le code postal pour rechercher la bonne succursale',
      create_lead: 'Opportunité MH',
      expert_cap_button_label: 'Attribuer à un CAP expert',
      appointment_booking_not_available_for_zipcode: 'Désolé, la prise de rendez-vous n’est pas disponible pour ce code postal. Créer un nouveau client afin qu’une CAP puisse le contacter par téléphone.'
    },
    step2: {
      title: 'Ressource',
      chooseEmployee: 'Choisissez un ressource disponible',
      name: 'Nom',
      function: 'fonction',
      noSpecificWorker: 'Peu importe qui',
      noSpecificFunction: 'Tous les départements',
      exception: "Définir l'exception de l'employé",
      nextButton: 'Prochain'
    },
    step3: {
      title: 'Date',
      chooseDate: 'Sélectionnez la date souhaitée',
      morning: 'demain',
      forenoon: 'matin',
      noon: 'midi',
      afternoon: 'après-midi',
      evening: 'soir',
      night: 'nuit',
      infoMsg: 'Veuillez noter qu\'il est possible de réserver plusieurs rendez-vous en même temps. Sélectionnez simplement les dates souhaitées via la case à cocher. Les dates seront ensuite ajoutées au panier.',
      appointment: 'Rendez-vous',
      employee: 'Ressource',
      proposals: 'proposition de rendez-vous',
      nextButton: 'Continuer',
      noFreeAppointments: 'Malheureusement, il n\'y a pas de dates libres ce jour-là!',
      cartNotSupported: 'Ce fournisseur ne prend en charge qu\'un seul enregistrement de créneau par réservation.',
      cartSupported: 'Ce fournisseur prend en charge plusieurs enregistrements par réservation.',
      workerSelectLable: "Sélectionnez un conseiller personnel :",
      workerSelectLinkPrefix: "Vous n'avez pas trouvé de rendez-vous avec votre conseiller ?",
      workerSelectShowAllLinkPostfix: "Afficher tous les conseillers",
      workerSelectHideAllLinkPostfix: "Masquer tous les conseillers"
    },
    step4: {
      comment_helper_text: '2000 caractères maximum',
      title: 'finaliser',
      summary: 'Résumé',
      company: 'Entreprise',
      store: 'Filiale',
      employee: 'Ressource',
      bookedServices: 'Services',
      duration: 'durée',
      price: 'prix',
      finalPrice: 'coûts',
      total: 'Total',
      yourData: 'Vos données',
      disclaimer: 'Veuillez entrer vos coordonnées. Tous les champs marqués d\'un * sont obligatoires.',
      prename: {
        label: 'Prénom',
        placeholder: 'Prénom',
        error: {
          minChars: 'Le prénom doit avoir au moins {{requiredLength}} caractères.',
          required: 'S\'il vous plaît donnez votre prénom.'
        }
      },
      lastname: {
        label: 'Nom',
        placeholder: 'Nom',
        error: {
          minChars: 'Le nom doit avoir au moins {{requiredLength}} caractères.',
          required: 'S\'il vous plaît donnez votre nom.'
        }
      },
      phone: {
        label: 'Téléphone mobile',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Le numéro de téléphone ne peut contenir que des chiffres.',
          minChars: 'Le numéro de téléphone doit contenir au moins {{requiredLength}} caractères.',
          required: 'Veuillez entrer un numéro de téléphone.'
        }
      },
      eMail: {
        label: 'E-Mail',
        error: {
          pattern: 'Veuillez entrer une adresse E-mail valide.',
          minChars: 'L\'adresse e-mail doit avoir au moins {{longueur requise}} caractères.',
          required: 'S\'il vous plaît entrer une adresse e-mail.'
        }
      },
      message: {
        label: 'Message',
        placeholder: ''
      },
      internal_comment: {
        label: 'Commentaire interne',
        placeholder: 'Ajouter des commentaires à usage interne',
        comment_helper_text: '5000 caractères maximum'
      },
      company_name: {
        label: 'Nom de l\'entreprise',
        error: {
          minChars: 'Ce champ nécessite au moins {{requiredLength}} caractères.'
        }
      },
      customFields: {
        defaultOption: 'Veuillez sélectionner une option',
        error: {
          maxChars: 'Ce champ permet un maximum de {{ requiredLength }} caractères.',
          minChars: 'Ce champ nécessite au moins {{requiredLength}} caractères.',
          required: 'Ce champ est obligatoire.',
          questionisRequired: "{{ text }} est obligatoire",
          checkboxisRequired: "Cette case à cocher doit être sélectionnée pour continuer."
        }
      },
      cart: {
        emptyCartWarning: 'Il n\'y a actuellement aucun rendez-vous dans le panier.',
        removeIcon: 'Supprimer le rendez-vous du panier.',
        waiting_list: 'Liste d’attente'
      },
      numberSelect: {
        increase: 'Augmenter la valeur',
        decrease: 'Diminuer la valeur'
      }
    },
    finalpage: {
      successTitle: 'Le rendez-vous a été réservé avec succès',
      successMessageDu: 'La réservation de rendez-vous a été transmise avec succès. Une confirmation sera envoyée par courrier électronique ou par SMS. <br> <br> Si, pour une raison quelconque, vous ne pouvez pas participer à l\'événement, nous vous prions d\'annuler votre participation en utilisant le lien d\'annulation figurant dans l\'e-mail ou le SMS.',
      successMessageSie: 'La réservation de rendez-vous a été transmise avec succès. Une confirmation sera envoyée par courrier électronique ou par SMS. <br> <br> Si, pour une raison quelconque, vous ne pouvez pas participer à l\'événement, nous vous prions d\'annuler votre participation en utilisant le lien d\'annulation figurant dans l\'e-mail ou le SMS.',
      errorTitle: 'Une erreur est survenue.',
      errorMessage: 'Malheureusement, le rendez-vous n\'a pas pu être réservé. Veuillez réessayer.',
      errorMessageDu: "Le rendez-vous n'a malheureusement pas pu être réservé. Merci de réessayer.",
      errorMessageSie: "Le rendez-vous n'a malheureusement pas pu être réservé. Merci de réessayer.",
      bookNextAppointment: 'Réserver un autre rendez-vous',
      bookAppointmentAgain: 'Retourner'
    },
    error: {
      noworkers: 'Malheureusement, ce fournisseur n\'a défini aucun ressource pouvant être réservé.'
    }
  },
  events: {
    list: "Liste",
    grid: "Grille",
    noEvents: "Le fournisseur n'a pas encore enregistré d'événements.",
    hidden_events_message: "Les événements de ce partenaire sont cachés. Veuillez utiliser le lien direct vers l'événement que vous avez reçu du partenaire afin de vous inscrire.",
    filter_result_null: "Aucun résultat ne correspond à tes critères.",
    step1: {
      title: "Événement",
      moreInformation: "Plus d'informations",
      noFreeSlots: "Plus d'emplacement libre",
      noEvents: "Aucun événement n'a encore été créé.",
      showDescription: "Afficher la description complète",
      hideDescription: "Masquer la description",
      book: "Livre",
      noPlaceDefined: "Pas de lieu défini",
      store_filter: "Filtre de magasin",
      category_filter: "Filtre de catégorie",
      all_categories: "Tous les"
    },
    step2: {
      title: 'Créneau horaire',
      slots: 'Créneau horaire',
      chooseDate: 'Sélectionnez la date souhaitée',
      booked: 'Réservé',
      capacity: 'Disponible',
      noFreeDates: 'Il n\'y a pas d\'événements libres pour ce partenaire',
      bookedOut: 'Cette fenêtre de temps est déjà réservée',
      cartNotSupported: 'Ce fournisseur ne prend en charge qu\'un seul enregistrement de créneau par réservation.',
      cartSupported: 'Ce fournisseur prend en charge plusieurs enregistrements par réservation.',
      alreadyBookedByUser: 'Vous avez déjà réservé cette place. En cas de problème, veuillez réinitialiser l\'application.',
      waitinglist: 'liste d\'attente: {{ capacity }} libre',
      fully_booked: "Complet",
      regular_count_singular: '<b>1</b> espace libre',
      regular_count_plural: '<b>{{ number }}</b> places libres',
      waiting_count_singular: "1 place libre sur la liste d'attente",
      waiting_count_plural: "{{ number }} places libres sur la liste d'attente",
      free_seats: 'Places disponibles',
      free_waiting_seats: 'Places disponibles sur liste d\'attente',
      multiday_group_appointment: "Ceci est un rendez-vous de groupe sur plusieurs jours"
    },
    step3: {
      title: 'Résumé',
      summary: 'Resumé',
      company: 'Entreprise',
      store: 'Filiale',
      employee: 'Ressource',
      bookedEvent: 'Service',
      date: 'date',
      time: 'temps',
      price: 'prix',
      finalPrice: 'coûts',
      total: 'total',
      yourData: 'Vos données',
      disclaimer: 'Veuillez entrer vos coordonnées. Tous les champs marqués d\'un * sont obligatoires.',
      cart: {
        emptyCartWarning: 'Il n\'y a actuellement aucun événement dans le panier',
        waiting_list: 'Liste d’attente'
      }
    },
    finalpage: {
      successTitle: 'Vous avez réservé avec succès un ou plusieurs événements.',
      successMessageDu: 'L\'inscription à l\'événement a été soumise avec succès. Une confirmation sera envoyée par courrier électronique ou par SMS. <br> <br> Si, pour une raison quelconque, vous ne pouvez pas participer à l\'événement, nous vous prions d\'annuler votre participation en utilisant le lien d\'annulation indiqué dans l\'e-mail ou le SMS.',
      successMessageSie: 'L\'inscription à l\'événement a été soumise avec succès. Une confirmation sera envoyée par courrier électronique ou par SMS. <br> <br> Si, pour une raison quelconque, vous ne pouvez pas participer à l\'événement, nous vous prions d\'annuler votre participation en utilisant le lien d\'annulation indiqué dans l\'e-mail ou le SMS.',
      errorTitle: 'Une erreur est survenue.',
      errorMessage: 'Malheureusement, une erreur s\'est produite lors de l\'enregistrement. Veuillez réessayer.',
      bookNextAppointment: 'Inscription supplémentaire',
      bookAppointmentAgain: 'Retourner'
    }
  },
  resources: {
    noResources: 'Le fournisseur n\'a pas encore enregistré de ressources.',
    step1: {
      title: 'ressource',
      chooseResource: 'Choisir les ressources'
    },
    step2: {
      title: 'créneau horaire',
      startDate: 'date de début: ',
      endDate: 'date de fin: ',
      startTime: 'Veuillez choisir l\'heure de début',
      endTime: 'S\'il vous plaît choisir l\'heure de fin',
      resetCalendar: 'Entrez une nouvelle date dans le calendrier',
      noFreeResources: 'Aucune ressource réservable trouvée pour la période',
      selectDate: 'Sélectionner la date ou la période'
    },
    step3: {
      title: 'résumé',
      summary: 'résumé',
      company: 'entreprise',
      store: 'filiale',
      employee: 'ressource',
      bookedEvent: 'service',
      date: 'date',
      time: 'temps',
      price: 'prix',
      finalPrice: 'coûts',
      total: 'total',
      yourData: 'Vos données',
      disclaimer: 'Veuillez entrer vos coordonnées. Tous les champs marqués d\'un * sont obligatoires.',
      prename: {
        label: 'Prénom',
        placeholder: 'Prénom',
        error: {
          minChars: 'Le prénom doit être au moins {{ requiredLength }} caractères soient longs.',
          required: 'Veuillez indiquer votre prénom.'
        }
      },
      lastname: {
        label: 'Nom de famille',
        placeholder: 'Nom de famille',
        error: {
          minChars: 'Le nom de famille doit être au moins {{ requiredLength }} caractères soient longs.',
          required: 'Veuillez entrer le nom de famille.'
        }
      },
      phone: {
        label: 'Téléphone mobile',
        placeholder: '+4179xxxxxxx',
        error: {
          pattern: 'Le numéro de téléphone ne peut contenir que des chiffres.',
          minChars: 'Le numéro de téléphone doit être au moins {{ requiredLength }} Contenir les caractères.',
          required: 'Veuillez entrer un numéro de téléphone.'
        }
      },
      eMail: {
        label: 'E-Mail',
        error: {
          pattern: 'Veuillez entrer une adresse e-mail valide.',
          minChars: 'L\'adresse e-mail doit être au moins {{ requiredLength }} caractères soient longs.',
          required: 'Veuillez entrer une adresse e-mail.'
        }
      },
      message: {
        label: 'Message',
        placeholder: ''
      },
      customFields: {
        defaultOption: 'Veuillez choisir une option',
        error: {
          minChars: 'Ce champ requiert au moins {{ requiredLength }} signe.',
          required: 'Ce champ est obligatoire.'
        }
      },
      cart: {
        emptyCartWarning: 'Il n\'y a actuellement aucun événement dans le panier.'
      },
      From: 'De',
      To: 'À'
    },
    finalpage: {
      successTitle: 'Vous avez réservé avec succès une ou plusieurs ressources.',
      successMessageDu: 'L\'enregistrement de l\'événement a été soumis avec succès. Une confirmation sera envoyée par courrier électronique ou par SMS. <br> <br> Si pour une raison quelconque vous ne pouvez pas assister à l\'événement, nous vous demandons d\'annuler votre participation au moins {{leadTime}} heures à l\'avance via le lien d\'annulation dans l\'e-mail ou dans le SMS.',
      successMessageSie: 'L\'enregistrement de l\'événement a été soumis avec succès. Une confirmation sera envoyée par courrier électronique ou par SMS. <br> <br> Si, pour une raison quelconque, vous n\'êtes pas en mesure d\'assister à l\'événement, veuillez annuler votre participation au moins {{leadTime}} heures à l\'avance via le lien d\'annulation figurant dans l\'e-mail ou le message texte.',
      errorTitle: 'Une erreur est survenue.',
      errorMessage: 'Les ressources n\'ont pas pu être réservées. Veuillez réessayer.',
      bookNextEvent: 'Réserver une autre ressource',
      bookEventAgain: 'retourner'
    }
  },
  customer: {
    prename: {
      label: 'Prénom',
      placeholder: 'Prénom',
      error: {
        minChars: 'Le prénom doit avoir au moins {{requiredLength}} caractères.',
        required: 'S\'il vous plaît donnez votre prénom.'
      }
    },
    lastname: {
      label: 'Nom',
      placeholder: 'Nom',
      error: {
        minChars: 'Le nom doit avoir au moins {{requiredLength}} caractères.',
        required: 'S\'il vous plaît donnez votre nom.'
      }
    },
    phone: {
      label: 'Numéro de téléphone',
      placeholder: '+4179xxxxxxx',
      error: {
        pattern: 'Le numéro de téléphone ne peut contenir que des chiffres.',
        minChars: 'Le numéro de téléphone doit contenir au moins {{requiredLength}} caractères.',
        required: 'Veuillez entrer un numéro de téléphone.',
        invalid: 'Veuillez saisir un numéro de mobile valide.'
      }
    },
    eMail: {
      label: 'E-Mail',
      error: {
        pattern: 'Veuillez entrer une adresse E-mail valide.',
        minChars: 'L\'adresse e-mail doit avoir au moins {{requiredLength}} caractères.',
        required: 'S\'il vous plaît entrer une adresse e-mail.'
      }
    },
    street: {
      label: 'Rue / No.',
      placeholder: 'Nom de rue',
      error: {
        minChars: 'Le nom de la rue doit être au moins {{ requiredLength }} caractères.',
        required: 'Veuillez entrer le nom de votre rue.'
      }
    },
    zip: {
      label: 'CP',
      placeholder: 'cp code',
      error: {
        minChars: 'Le numéro de cp doit contenir au moins {{ requiredLength }} caractères.',
        required: 'Veuillez entrer votre numéro de cp.',
        pattern: "Veuillez entrer un code postal valide, s'il vous plaît.",
      }
    },
    city: {
      label: 'Ville',
      placeholder: 'Nom de ville',
      error: {
        minChars: 'La ville doit contenir au moins {{ requiredLength }} caractères.',
        required: 'Veuillez entrer le nom de votre ville.'
      }
    },
    message: {
      label: 'Message',
      placeholder: ''
    },
    customFields: {
      defaultOption: 'Veuillez sélectionner une option',
      error: {
        minChars: 'Ce champ nécessite au moins {{requiredLength}} caractères.',
        required: 'Ce champ est obligatoire.'
      }
    },
    captcha: {
      required: 'Ce champ est obligatoire.',
      invalid: 'Veuillez entrer un captcha valide.',
      valid: 'Captcha trouvé avec succès.',
      placeholder: 'Tapez le texte ci-dessus'
    }
  },
  stripe: {
    stripe_title: 'Payez votre prochaine visite en ligne',
    submit_payment: 'Soumettre le paiement',
    stripe_label: 'Carte de crédit ou carte de débit',
    online_payment_optional: 'Dans cette étape, vous avez la possibilité de régler votre prochaine visite maintenant. Il n\'y a pas de frais supplémentaires pour vous. Le paiement est effectué via un fournisseur sécurisé (Stripe). Nous ne stockons pas les informations de carte de crédit sur nos serveurs.',
    online_payment_mandatory: 'Pour compléter la réservation, nous vous demandons de payer votre prochaine visite maintenant. Il n\'y a pas de frais supplémentaires pour vous. Le paiement est effectué via un fournisseur sécurisé (Stripe). Nous ne stockons pas les informations de carte de crédit sur nos serveurs.'
  },
  alert_messages: {
    select_day_in_calendar_msg: "Sélectionnez un jour spécifique dans le calendrier pour obtenir des suggestions de dates possibles.",
    no_events_in_month: "Malheureusement, il n'y a plus de créneaux horaires libres dans le mois sélectionné.",
    no_events: "Malheureusement, cet événement n’a pas de fenêtre réservable à l’avenir. Par conséquent, aucun calendrier ne s’affiche pour sélectionner une date.",
    waitinglist_enabled_message: "Certains créneaux horaires sont déjà complets. Il est toutefois possible de s'inscrire sur la liste d'attente. Dès qu'une participation est annulée, la place suivante sur la liste d'attente prend le relais et reçoit une confirmation de participation.",
    waitinglist_booking_message: "Merci beaucoup Nous avons accepté votre réservation avec succès. Étant donné que vous êtes maintenant sur la liste d'attente d'au moins un événement, vous ne recevrez une confirmation de participation de notre part que si un autre participant ne peut pas assister à l'événement. Dans ce cas, vous serez averti par e-mail. Malheureusement, faute d'espace, vous ne pourrez pas assister à l'événement sans une confirmation officielle de notre site.",
    window_is_fully_booked: "Cette fenêtre est entièrement réservée (y compris liste d’attente).",
    waitinglist_is_available: "Ce créneau horaire est complet, mais il reste <b>{{ number }}</b> places disponibles sur la liste d'attente.",
    no_bookable_dates_message: "Malheureusement, il n'y a plus de jours réservables dans le mois de {{ month }}. Devrions-nous rechercher des dates libres dans les mois à venir ?",
    no_slots_dates_message: "Malheureusement, il n'y a plus de créneaux horaires réservables au mois de {{ month }}. Devrions-nous chercher des créneaux horaires libres dans les mois à venir ?",
    no_slots_dates_reached_message: "Malheureusement, il n'y a plus de créneaux horaires réservables au mois de {{ month }}. La période de réservation maximale est atteinte.",
    no_slots_years_reached_message: "Malheureusement, il n'y a plus de créneaux disponibles pour l'année {{ year }}. La période de réservation maximale a été atteinte.",
    no_bookable_dates_reached_message: "Malheureusement, il n'y a plus de dates à réserver pour le mois de {{ month }}. La période de réservation maximale est atteinte.",
    xss_error_message: "Ce texte contient des balises HTML non prises en charge.",
    slot_already_reserved_by_other_error: "Entre-temps, le créneau que tu essaies de réserver a été réservé par quelqu'un d'autre. Choisis un autre créneau et réessaie plus tard.",
    failed_to_reserve_slot_error: "Un problème est survenu lors de la réservation du créneau pour toi. Réessaie plus tard !",
    reserved_by_someone: "Réservé par quelqu'un d'autre"
  },
  coupon_credit_card_info: {
    redeem_coupon_1: 'Valider le coupon',
    apply_coupon: 'Appliquer le coupon',
    coupon: 'Coupon',
    credit_or_debit_card: 'Carte de crédit ou de débit',
    invalid_coupon: 'Coupon non valable',
    applied_successfully: 'Appliqué avec succès'
  },
  tax_info: {
    taxes: '(TVA {{percentage}}% comprise)'
  },
  new_alert_component: {
    x_day_ago: 'il y a x jour',
    x_days_ago: 'il y a x jours',
    x_minute_ago: 'il y a x minute',
    x_minutes_ago: 'il y a x minutes',
    x_hour_ago: 'il y a x heur',
    x_hours_ago: 'il y a x heurs',
    x_second_ago: 'il y a x seconde',
    x_seconds_ago: 'il y a x secondes',
    x_week_ago: 'il y a x semaine',
    x_weeks_ago: 'il y a x semaines',
    x_month_ago: 'il y a x mois',
    x_months_ago: 'il y a x mois',
    x_year_ago: 'il y a x année',
    x_years_ago: 'il y a x ans'
  },
  summary_page_translations: {
    paypal_warning_msg: 'Avant qu\'une réservation soit possible, le montant restant doit être payé avec PayPal.',
    paypal: 'PayPal',
    saferpay_payment_overlay: 'Cet écran se cache automatiquement dès que le paiement est terminé ou annulé.<br/>Si l\'écran de paiement se ferme accidentellement, veuillez appuyer sur le bouton de fermeture pour le masquer et recommencer le paiement.',
    or: 'ou',
    browser: 'Chercher',
    choose_file: 'Sélectionnez un fichier',
    clear: 'Réinitialiser',
    meeting_type: 'Où le rendez-vous doit-il avoir lieu ?',
    email: 'Courrier électronique',
    sms: 'SMS',
    both_sms_email: 'Courrier électronique et SMS',
    notification_type: 'Type de notification',
    payment_title: 'Paiement',
    payment_subtitle: 'Décidez comment vous voulez payer vos services',
    payment_subtitle_sie: 'Décidez comment vous voulez payer vos services',
    summary_title: 'Résumé',
    summary_subtitle: 'Vérifiez votre commande et envoyez-la',
    summary_subtitle_sie: 'Vérifiez votre commande et envoyez-la',
    payment_type: 'Type de paiement',
    creditcard: 'Carte de crédit',
    invoice: 'Facture',
    lexoffice: 'Facture (LexOffice)',
    store: 'Sur place',
    apply_coupon: 'Je souhaite appliquer un code de réduction',
    choose_payment_method: 'Choisissez le mode de paiement',
    tax: 'incl. {{ rate }}% TVA',
    subtotal: 'Sous-total',
    personal_form_title: 'Données personnelles',
    personal_form_subtitle: 'Dites-nous quelque chose sur vous',
    personal_form_subtitle_sie: 'Dites-nous quelque chose sur vous',
    inclusive: 'inclusif',
    paymentMandatory: 'Le fournisseur a défini que le service doit être payé en ligne à l\'avance. Veuillez donc choisir un mode de paiement.',
    file_upload: 'Téléchargement de fichiers',
    file_upload_helper_text: 'Plusieurs fichiers peuvent être joints',
    file_was_uploaded: '"{{name}}" a été téléchargé avec succès',
    max_file_size_5mb: 'Le fichier téléchargé est trop gros (5MB maximum sont autorisés).',
    malware_found: 'Un problème est survenu lors de la recherche de virus dans le fichier (Accès refusé). Veuillez contacter le support de Calenso !',
    multiple_file_upload_helper_text: 'Un maximum de 5 fichiers peut être téléchargé. Le fichier à télécharger ne doit pas dépasser 5 Mo.',
    single_file_upload_helper_text: '1 fichier peut être téléchargé. Le fichier à télécharger ne doit pas dépasser 5 MB.',
    file_uploading: 'Le téléchargement des fichiers est toujours en cours. Merci d\'attendre la fin de l\'action et d\'essayer de réserver à nouveau.',
    maximum_x_files: 'Un maximum de {{fileLength}} fichiers peut être téléchargé.',
    document_invalid_file_type: "Type de fichier non valide. Seuls les fichiers PDF et Excel sont autorisés.",
    image_invalid_file_type: "Type de fichier non valide, veuillez télécharger un fichier image valide.",
    request_sms_code: 'Demande code',
    mobile_number_must_be_verified: 'Le numéro doit être vérifié pour la réservation.',
    verification_code: 'Code de vérification',
    sms_code_verification_failed_msg: 'Malheureusement, le code inséré ne correspond pas au code que nous avons envoyé par SMS.',
    check: 'Vérifier',
    phone_number_verified_successfully: 'Le numéro a été vérifié avec succès',
    resend_sms_helper_msg_1: 'Je n\'ai pas reçu de code.',
    resend_sms_helper_msg_2: 'Demandez nouveau code.',
    resend_sms_successfully: 'Le code SMS a été renvoyé avec succès',
    exceeded_sms_verification_limit_msg: 'Ce numéro a dépassé le nombre maximum de codes de vérification. Veuillez contacter le support Calenso (<a href="mailto:support@calenso.com">support@calenso.com</a>).',
    salutation: 'Salutation',
    mr: 'Monsieur',
    ms: 'Madame',
    other: 'autre',
    select_payment_method: 'Veuillez d\'abord payer le montant ouvert en utilisant PayPal, avant de pouvoir soumettre la réservation.',
    accept_data_policy: 'J\'accepte les <a href="https://calenso.com/agb/" target="_blank">conditions générales de Calenso</a> et la <a href="https://calenso.com/datenschutz/" target="_blank">politique de confidentialité</a> pour l\'utilisation de mes données dans le cadre de la prise de rendez-vous et je les ai lues.',
    saferpay: 'Saferpay',
    saferpay_pay: 'Appuyez pour payer avec Saferpay',
    payment_success_title: 'Paiement réussi',
    payment_success_message: 'Le montant ouvert a été payé avec succès avec {{ paymentMethod }}. Le rendez-vous peut maintenant être enregistré. Si aucune comptabilisation n\'a lieu, aucun montant n\'est débité.',
    title: 'Titre',
    country: 'Pays',
    title_aria_describedby: "Titre spécial prenant en charge Dr., Dr. med., Prof.",
    salutation_aria_describedby: "Forme de salutation prenant en charge M., Mme et autres",
    prename_aria_describedby: "Prénom ou nom",
    lastname_aria_describedby: "Nom de famille",
    notification_type_aria_describedby: "Pour définir dans le processus de réservation comment vous souhaitez être notifié de la réservation.",
    email_describedby: "E-mail",
    phoneNumber_aria_describedby: "Numéro de téléphone",
    smsPhoneCode_aria_describedby: "OTP pour vérifier le numéro de téléphone",
    mobileNumber_aria_describedby: "Numéro de portable",
    smsMobileCode_aria_describedby: "OTP pour vérifier le numéro de portable",
    street_aria_describedby: "Adresse",
    zip_aria_describedby: "Code postal",
    city_aria_describedby: "Ville",
    country_aria_describedby: "Pays",
    comment_aria_describedby: "Commentaires supplémentaires si vous en avez",
    internal_comment_aria_describedby: "Commentaire interne (à usage interne uniquement)",
    company_aria_describedby: "Nom de l'entreprise",
    captcha_aria_describedby: "Captcha"
  },
  buttons: {
    yes_search: 'Oui, la recherche.',
    remove: 'Retirer'
  },
  add: 'Ajouter',
  compact: {
    no_service: 'Aucun rendez-vous ne peut actuellement être pris car aucun service correspondant n\'a été trouvé.',
    navigation: {
      date: 'Date',
      date_slots: 'Date et créneau horaire',
      personal_info: 'Données personnelles'
    }
  }
};
